import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController, NavController, ToastController, AlertController, LoadingController } from '@ionic/angular';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { DataService } from '../services/data.service';
import { File } from '@ionic-native/file/ngx';
import { Contacts, ContactFieldType } from '@ionic-native/contacts/ngx';
import { Device } from '@ionic-native/device/ngx';
import { ContactsService } from '../services/contacts.service';

@Component({
    selector: 'app-select-contact',
    templateUrl: './select-contact.page.html',
    styleUrls: ['./select-contact.page.scss'],
})
export class SelectContactPage implements OnInit {
    data: any;
    objcontacts: any = [];
    contactList: any[] = [];
    ourtype: ContactFieldType[] = ['displayName', 'phoneNumbers', 'emails'];
    selectedContacts: any = [];
    
    constructor(
        private http: HttpClient,
        private contacts: Contacts,
        public navCtrl: NavController,
        public toastController: ToastController,
        public loadingController: LoadingController,
        private device: Device,
        public alertController: AlertController,
        public modalController: ModalController,
        private dataService: DataService,
        private navParams: NavParams,
        private contactsService: ContactsService,
    ) { }

    async ngOnInit() {
        this.data = await this.navParams.get('data');
        this.objcontacts = this.contactsService.objcontacts;
    }

    ionViewWillEnter() {
        this.dataService.checkSession();
        if (this.contactsService.objcontacts) {
            this.objcontacts = this.contactsService.objcontacts;
        }
    }

    close() {
        this.modalController.dismiss(false);
    }

    sendSelectedContact() {
        this.modalController.dismiss(this.selectedContacts);
    }

    getnumbersArray(numList) {
        const a = [];
        numList.forEach(num => {
            a.push(num.value.replace(/ /g, ""));
        });
        return a;
    }

    async getContactsFromDevice() {
        await this.dataService.presentLoading({
            message: 'Synchronizing Contacts...',
        });
        this.contactList = [];
        this.contacts.find(this.ourtype).then(ct => {
            this.dataService.stopLoader();

            ct.forEach(contact => {
                if (contact['phoneNumbers'] != null) {
                    if (this.device.platform == 'Android') {
                        var name = contact.displayName
                    } else {
                        var name = contact.name.givenName
                    }
                    const obj = {
                        Name: name,
                        Number: this.getnumbersArray(contact['phoneNumbers'])
                    };
                    this.contactList.push(obj);
                }
            });
            this.getSubscribedContacts(this.contactList);
        }).catch(err => {
            // console.error(err);
            this.dataService.stopLoader();
        });
    }

    async getSubscribedContacts(ctl) {
        await this.dataService.presentLoading({
            message: 'Please Wait...',
        });
        const HttpParam = new HttpParams()
            .set('deviceId', this.dataService.deviceId)
            .set('contactList', JSON.stringify(ctl));

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        };

        const url = await this.dataService.getUrl();
        this.http.post(url + "/syncContacts", HttpParam, httpOptions)
            .subscribe(async (data: any) => {
                this.dataService.stopLoader();
                if (data.SyncedList !== []) {

                    // Genrate list of synced contacts device ids.
                    this.getContactIds(data.SyncedList)
                        .then(contactNumbers => {

                            const HttpParam = new HttpParams()
                                .set('deviceId', this.dataService.deviceId)
                                .set('contacts', encodeURIComponent(JSON.stringify(contactNumbers)));

                            this.http.post(url + "/_syncContacts", HttpParam, httpOptions)
                                .subscribe((_data: any) => {
                                    
                                    this.objcontacts = _data;
                                }, (error: any) => {
                                    // console.error(error);
                                    this.dataService.stopLoader();
                                });
                        }).catch(err => {
                            // console.error(err);
                        })

                }
            }, (error: any) => {
                // console.error(error);
                this.dataService.stopLoader();
            });
    }

    /**
     * Genrate list of synced contacts device ids.
     * @param contacts Contacts object containing DeviceId.
     */
    async getContactIds(contacts: any) {
        return new Promise((res, rej) => {
            try {
                var i = 0,
                    contactIds = [];
                while (contacts.length > i) {
                    var contact = contacts[i];
                    i++;
                    if (contactIds.indexOf(contact.RegisteredNumber) == -1) {
                        contactIds.push(contact.RegisteredNumber);
                    }
                    if (contacts.length == i) {
                        res(contactIds);
                    }
                }
            } catch (err) {
                rej(err);
            }
        })
    }

    /**
     * @description Select contact and Push it into another array and pop if already in array
     * @param contact Contact that is to be pushed into selected contact's array
     * @param index index of the selected contact to be removed
     */
    selectContact(contact) {
        if (this.selectedContacts.indexOf(contact) == -1) {
            this.selectedContacts.push(contact);
        } else {
            this.selectedContacts.splice(this.selectedContacts.indexOf(contact), 1);
        }
    }

    isSelected(contact) {
        if (this.selectedContacts.indexOf(contact) == -1) {
            return false;
        } else {
            return true;
        }
    }

    async refresh(event) {
        if (this.selectedContacts.length == '0') {
            await this.getContactsFromDevice();
        }
        event.target.complete();
    }

}
