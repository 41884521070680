import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';
import { SafePipeModule } from '../safe.pipe.module';
import { MiniPlayerPage } from './mini-player.page';
import { DurationPipeModule } from "../duration.pipe.module";

const routes: Routes = [
  {
    path: '',
    component: MiniPlayerPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    SafePipeModule,
    DurationPipeModule
  ],
  declarations: [MiniPlayerPage],
  entryComponents: [MiniPlayerPage]
})
export class MiniPlayerPageModule {}
