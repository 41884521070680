import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'duration'
})
export class DurationPipe implements PipeTransform {
    transform(value: any, args?: any): String {
        switch (value) {
            case null:
                throw new Error(`Invalid Value specified: ${value}`);
            case '':
                throw new Error(`Invalid Value specified: Empty string`);
            case undefined:
                throw new Error(`Undefined Value`);
            default:
                var h: any = parseInt((value / 3600).toString());
                var m: any = parseInt((value / 60).toString());
                var s: any = parseInt((value % 60).toString());

                if (m > 60) {
                    while (m > 60) {
                        m = m - 60;
                    }
                }

                if (h >= 0 && h <= 9) {
                    h = '0' + h;
                }
                if (m >= 0 && m <= 9) {
                    m = '0' + m;
                }
                if (s >= 0 && s <= 9) {
                    s = '0' + s;
                }

                if (h == '00' && m == '00') {
                    if (args == 'full') {
                        return `${s} sec`;
                    } else {
                        return `${m}:${s}`;
                    }
                } else if (h == '00') {
                    if (args == 'full') {
                        return `${m} min ${s} sec`;
                    } else {
                        return `${m}:${s}`;
                    }
                } else {
                    if (args == 'full') {
                       return `${h} Hour ${m} min ${s} sec`;
                    } else {
                        return `${h}:${m}:${s}`;
                    }
                }


        }
    }
}
