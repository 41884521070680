import { Injectable } from '@angular/core';
import { Track } from "../track";
import { Howl } from 'howler';
import { MusicControls } from '@ionic-native/music-controls/ngx';
import { DataService } from './data.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class MusicService {

    Playlist: Track[] = [];
    player: Howl;
    activeTrack: Track;
    isPlaying: boolean = false;
    isStoped: boolean = true;
    buffering: Track;
    duration: number;
    timeRemaining: any;
    elapsed: number;
    __updateProgress: any;
    inPlayer: boolean = true;
    liked_songs: any[] = [];
    __repeat: number = 1;
    fullData: any;

    constructor(
        private musicControls: MusicControls,
        private dataService: DataService,
        private http: HttpClient,
    ) { }

    async start(track: Track) {
        this.isStoped = false;
        if (this.player) {
            this.player.stop();
        }
        var mediaURL = await this.dataService.getMediaUrl();
        this.elapsed = 0;

        if (this.buffering == this.activeTrack) {

            if (!this.dataService.isBrowser) {
                var music_ctrl_object = {
                    track: track.name,
                    artist: 'N/A',
                    cover: mediaURL + track.album_art,

                    isPlaying: true,
                    dismissable: false,

                    hasPrev: true,
                    hasNext: true,
                    hasClose: false,

                    // IOS only
                    hasSkipForward: true,  // show skip forward button, optional, default: false
                    hasSkipBackward: true,

                    // Android only, optional
                    // text displayed in the status bar when the notification (and the ticker) are updated, optional
                    ticker: 'Now playing "' + track.name + '"',

                    playIcon: 'media_play',
                    pauseIcon: 'media_pause',
                    prevIcon: 'media_prev',
                    nextIcon: 'media_next',
                    closeIcon: 'media_close',
                    notificationIcon: 'notification'
                }

                if (track.artist) {
                    music_ctrl_object['artist'] = track.artist;
                }

                if (this.Playlist.length == 0) {
                    music_ctrl_object['hasPrev'] = false;
                    music_ctrl_object['hasNext'] = false;
                    music_ctrl_object['hasSkipForward'] = false;
                    music_ctrl_object['hasSkipBackward'] = false;
                }

                this.musicControls.create(music_ctrl_object);
                this.musicControls.subscribe().subscribe(action => {
                    console.log(action)
                    const message = JSON.parse(action).message;
                    switch (message) {
                        case 'music-controls-next':
                            this.next();
                            break;
                        case 'music-controls-previous':
                            this.prev();
                            break;
                        case 'music-controls-pause':
                            this.togglePlayer(true);
                            break;
                        case 'music-controls-play':
                            this.togglePlayer(false);
                            break;
                        case 'music-controls-destroy':
                            this.stopPlayer();
                            break;

                        // External controls (iOS only)
                        // case 'music-controls-toggle-play-pause':
                        //     this.togglePlayer(this.isPlaying);
                        //     break;
                        // case 'music-controls-seek-to':
                        //     const seekToInSeconds = JSON.parse(action).position;
                        //     this.player.seek(seekToInSeconds);
                        //     this.musicControls.updateElapsed({
                        //         elapsed: seekToInSeconds,
                        //         isPlaying: true
                        //     });
                        //     // Do something
                        //     break;
                        // case 'music-controls-skip-forward':
                        //     this.next();
                        //     break;
                        // case 'music-controls-skip-backward':
                        //     this.prev();
                        //     break;

                        // Headset events (Android only) -------- // comment this block when building IOS
                        case 'music-controls-headset-unplugged':
                            if (this.isPlaying) {
                                this.togglePlayer(true);
                            }
                            break;
                        case 'music-controls-headset-plugged':
                            if (!this.isPlaying) {
                                this.togglePlayer(false);
                            }
                            break;
                        //----------------------------------------
                        default:
                            break;
                    }
                });

                this.musicControls.listen();
                this.musicControls.updateIsPlaying(true);
            }
            this.buffering = track;
            this.duration = track.Duration;
            this.player = new Howl({
                src: [mediaURL + track.path],
                html5: true,
                buffer: true,
                onplay: () => {
                    this.isPlaying = true;
                    this.activeTrack = track;
                    if (!this.__updateProgress) {
                        this.updateProgress();
                    }
                },
                onend: () => {
                    this.next();
                },
                onloaderror: () => {
                    this.dataService.presentToast('Unable to load song. Playing next', 'bottom', 'error', 1500)
                    this.next();
                },
                onplayerror: () => {
                    this.dataService.presentToast('Unable to play song. Playing next', 'bottom', 'error', 1500)
                    this.next();
                }
            });
            this.player.load();
            this.player.play();
        }
        return this.player;
    }

    togglePlayer(pause: boolean) {
        this.isPlaying = !pause;
        if (!this.dataService.isBrowser) {
            this.musicControls.updateIsPlaying(!pause)
            this.musicControls.updateDismissable(pause)
        }
        if (pause) {
            this.player.pause();
        } else {
            this.player.play();
        }
    }

    next() {
        this.elapsed = 0;
        let index = this.Playlist.indexOf(this.activeTrack);
        if (this.__repeat == 3) {
            this.start(this.Playlist[index]);
        } else {
            if (index != this.Playlist.length - 1) {
                this.start(this.Playlist[index + 1]);
            } else {
                if (this.__repeat == 2) {
                    this.start(this.Playlist[0]);
                } else {
                    this.stopPlayer();
                }
            }
        }
    }

    prev() {
        this.elapsed = 0;
        let index = this.Playlist.indexOf(this.activeTrack);
        if (this.__repeat == 3) {
            this.start(this.Playlist[index]);
        } else {
            if (index > 0) {
                this.start(this.Playlist[index - 1]);
            } else {
                this.start(this.Playlist[this.Playlist.length - 1]);
            }
        }
    }

    updateProgress() {
        this.elapsed = this.player.seek();
        this.duration = this.player.duration();
        if (typeof this.elapsed == typeof 1) {
            this.timeRemaining = this.duration - this.elapsed;
        }
        if (this.__updateProgress) {
            clearTimeout(this.__updateProgress);
            this.__updateProgress = null;
            this.updateProgress();
            return
        }
        this.__updateProgress = setTimeout(() => {
            this.updateProgress();
        }, 1000);
    }

    pad2(number: number) {
        return (number < 10 ? '0' : '') + number
    }

    stopPlayer() {
        if (this.player) {
            this.player.stop();
        }

        this.elapsed = 0;
        if (!this.dataService.isBrowser) {
            this.musicControls.updateIsPlaying(false);
            this.musicControls.updateDismissable(true);
            this.musicControls.destroy();
        }
        this.isStoped = true;
    }

    async addToLiked(song: any) {
        const url = await this.dataService.getUrl() + '/createPlaylist';

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        };

        const HttpParam = new HttpParams()
            .set('userId', this.dataService.userData._id)
            .set('musicId', song._id);

        this.http.post(url, HttpParam, httpOptions)
            .subscribe(async (data: any) => {
                this.dataService.liked_songs_data.push(data);
                this.dataService.presentToast('Added to Your Play List', 'bottom', 'medium', 3000);
            }, (error) => {
                this.dataService.presentToast(error.error.message, 'top', 'error', 4000);
            });
    }

    async removeFromLiked(data: any) {

        var song = this.dataService.liked_songs_data.find(e => e.data.musicId == data._id);

        const url = await this.dataService.getUrl() + '/deletePlaylistById/' + song.data._id;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        };

        this.http.delete(url, httpOptions)
            .subscribe((data: any) => {
                this.dataService.liked_songs_data.splice(this.dataService.liked_songs_data.indexOf(song), 1);

                this.dataService.presentToast('Removed from Your Play List', 'bottom', 'medium', 3000);
            }, (error) => {
                this.dataService.presentToast(error.error.message, 'top', 'error', 4000);
            });
    }
}
