import { Injectable } from '@angular/core';
import { ToastController, LoadingController, AlertController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { Device } from '@ionic-native/device/ngx';
import { File } from '@ionic-native/file/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    isBrowser: boolean;
    deviceId: any;
    devicePlatform: any;
    deviceVersion: any;
    app_version: any;
    sessionData: any;
    token: any;
    liked_songs_data: any[] = [];
    // liked_songs: any[] = [];

    constructor(
        private file: File,
        private device: Device,
        private router: Router,
        private http: HttpClient,
        public toastController: ToastController,
        public loadingController: LoadingController,
        public alertController: AlertController,
        private appVersion: AppVersion,
        private iab: InAppBrowser,
        public platform: Platform,
        private firebaseX: FirebaseX
    ) { }

    view_tab_bar: boolean = true;
    userData: any = {};
    fileList: any[];

    async getUrl() {
        return 'http://api.ivaishnav.org:3000';
    }

    async getMediaUrl() {
        return 'https://ivaishnav.s3.amazonaws.com/';
    }

    /**
     * @description To preset toast
     * @param {String} msg Test message for toaster
     * @param {String} pos position of toaster | 'top', 'bottom'
     * @param {String} type Type of toaster | 'success', 'error'
     * @param {Number} time Toaster fade out delay time in ms
     */
    async presentToast(msg, pos, type, time) {
        const toast = await this.toastController.create({
            message: msg,
            position: pos,
            color: type,
            duration: time,
        });
        toast.present();
    }

    /**
     * @description Present loading
     * @param options angular's loading options
     */
    async presentLoading(options: any) {
        const loading = await this.loadingController.create(options);
        await loading.present();
    }

    /**
     * @description Stop loader
     */
    async stopLoader() {
        await this.loadingController.getTop()
            .then(e => {
                if (e) {
                    this.loadingController.dismiss();
                }
            })
    }

    /**
     * Get token
     */
    updateToken(tokenId?) {
        const tokenAlertButton = [{
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
        }, {
            text: 'Retry',
            handler: () => {
                this.updateToken();
            }
        }]
        this.platform.ready().then(() => {
            this.firebaseX.getToken()
                .then(async token => {
                    if (tokenId != token) {
                        const url = await this.getUrl() + '/refreshToken/' + this.userData._id;
                        const httpOptions = {
                            headers: new HttpHeaders({
                                'Content-Type': 'application/x-www-form-urlencoded'
                            })
                        };
                        const HttpParam = new HttpParams()
                            .set('tokenId', token);
                        this.http.post(url, HttpParam, httpOptions)
                            .subscribe((data: any) => {
                            });
                    }

                })
                .catch(async error => {
                    // console.error(error);
                    await this.alert('Error', 'Error connection server please try again!', tokenAlertButton);
                });
        });
    }

    async alert(header: string, message: string, button: any[]) {
        const alert = await this.alertController.create({
            header: header,
            message: message,
            backdropDismiss: false,
            buttons: button
        });
        await alert.present();
    }

    async getDirData() {
        var path: string;
        if (this.device.platform == 'iOS') {
            path = this.file.syncedDataDirectory;
        } else {
            path = this.file.externalRootDirectory;
        }
        this.fileList = [];

        await this.file.listDir(path, 'iVaishnav')
            .then(res => {
                res.forEach(el => {
                    this.fileList.push(el.name.split('.').slice(0, -1).join('.'))
                });
            }).catch(err => {
                // console.error(err)
            })
        return this.fileList;
    }

    async checkDevice() {
        if (this.device.platform === 'browser') {
            this.isBrowser = true;

            if (window.localStorage.getItem('IvaishnavToken')) {
                await this.checkSession();
            } else {
                this.router.navigateByUrl('/user-login');
                return;
            }
        } else {
            this.appVersion.getVersionNumber().then(async app_version => {
                this.app_version = app_version;
                
                this.deviceId = this.device.uuid;
                this.devicePlatform = this.device.platform;
                this.deviceVersion = this.device.version;
                await this.checkIfSubscribed();
            }).catch(err => {
                // console.error(err);
            })
        }
    }

    async checkIfSubscribed() {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        };

        const HttpParam = new HttpParams()
            .set('deviceId', this.deviceId)
            .set('version', this.deviceVersion)
            .set('appVersion', this.app_version)
            .set('device', this.devicePlatform);

        const url = await this.getUrl() + '/checkUserAppVersion';
        this.http.post(url, HttpParam, httpOptions)
            .subscribe(async (data: any) => {
                this.userData = data;
                this.getPlaylist();
                if (!this.isBrowser) {
                    this.updateToken(data.tokenId);
                    if (data.appVersion != this.app_version) {
                        await this.VersionAlert();
                    }
                }
                if (this.userData.isActive == false && this.userData.neverShowAgain == false) {
                    if (!this.isBrowser) {
                        this.router.navigateByUrl('/subscribe');
                    }
                }
            });
    }

    async VersionAlert() {
        if (this.device.platform !== 'browser') {
            const alert = await this.alertController.create({
                header: "Update",
                message: 'New Version Available!, Download it to access new features.',
                backdropDismiss: false,
                buttons: [{
                    text: 'Ok',
                    handler: async () => {
                        if (this.device.platform == "Android") {
                            this.iab.create('https://play.google.com/store/apps/details?id=org.vaishnav.app', '_system');
                        }
                        if (this.device.platform == "iOS") {
                            this.iab.create('https://apps.apple.com/us/app/org.vaishnav.app/1497423277', '_system');
                        }
                    }
                }]
            });
            await alert.present();
        }
    }

    async checkSession() {
        if (this.device.platform === 'browser') {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            };

            const url = await this.getUrl() + '/varifyToken';

            const HttpParam = new HttpParams()
                .set('token', window.localStorage.getItem('IvaishnavToken'));

            this.http.post(url, HttpParam, httpOptions)
                .subscribe(async (data: any) => {
                    this.sessionData = data;
                    this.deviceId = this.sessionData['userId'];
                    this.devicePlatform = this.sessionData['platform'];
                    this.deviceVersion = this.sessionData['version'];
                    await this.checkIfSubscribed();
                }, (error) => {
                    var toasterData = {
                        msg: 'Sesson Expired. Login Again',
                        position: 'top',
                        color: 'error',
                        duration: 4000,
                    }
                    this.presentToast(toasterData.msg, toasterData.position, toasterData.color, toasterData.duration);
                    this.router.navigateByUrl('/user-login');
                });
        }
    }

    async getPlaylist() {
        var apiURL = await this.getUrl();

        const url = apiURL + "/findPlaylistByUserId/" + this.userData._id;
        this.http.get(url)
            .subscribe((data: any) => {
                this.liked_songs_data = data;
            }, (error: any) => {
                this.liked_songs_data = [];
                // console.error(error);
            });
    }

}
