import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { DataService } from '../services/data.service';

@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.page.html',
    styleUrls: ['./video-player.page.scss'],
})
export class VideoPlayerPage implements OnInit, OnDestroy {
    discourseswatch: any;

    constructor(
        private dataService: DataService,
        private navParams: NavParams,
        public modalController: ModalController,
        private screenOrientation: ScreenOrientation
    ) { }

    async ngOnInit() {
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
        this.discourseswatch = this.navParams.get('data');
    }

    ionViewWillEnter() {
        this.dataService.checkSession();
    }

    ngOnDestroy() {
        this.screenOrientation.unlock();
    }

    close() {
        this.modalController.dismiss();
    }

}
