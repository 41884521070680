import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, LoadingController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from '../services/data.service';

@Component({
    selector: 'app-location-model',
    templateUrl: './location-model.page.html',
    styleUrls: ['./location-model.page.scss'],
})
export class LocationModelPage implements OnInit {
    data: any = null;
    locationList: any[] = [];
    fulldata: any[] = [];
    countryList: any;
    selectedCountry: string = null;
    fullCountryList: any;
    searchVal: string;
    constructor(
        public alertController: AlertController,
        private http: HttpClient,
        public modalController: ModalController,
        public dataService: DataService,
        public loadingController: LoadingController
    ) { }

    async ngOnInit() {
        this.getAllCountryList();
    }

    ionViewWillEnter() {
        this.dataService.checkSession();
    }

    async getAllData() {
        this.searchVal = '';
        await this.dataService.presentLoading({
            message: 'Please Wait...',
        });
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        };

        const url = await this.dataService.getUrl();
        this.http.get(url + '/getAllCityListData/' + this.selectedCountry, httpOptions)
            .subscribe((data: any) => {
                this.dataService.stopLoader();
                this.locationList = data;
                this.fulldata = data;
            }, (err: any) => {
                this.dataService.stopLoader();
                // console.error(err);
            });
    }

    async getAllCountryList() {
        await this.dataService.presentLoading({
            message: 'Please Wait...',
        });
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        };

        const url = await this.dataService.getUrl();
        this.http.get(url + '/getAllCountryList', httpOptions)
            .subscribe((data: any) => {
                this.dataService.stopLoader();
                this.countryList = data;
                this.fullCountryList = data;
            }, (err: any) => {
                this.dataService.stopLoader();
                // console.error(err);
            });
    }

    searchData(ev) {
        const val = ev.target.value;
        if (this.fulldata.length > 0) {
            if (val && val.trim() !== '') {
                this.locationList = this.fulldata.filter((item) => {
                    const f1 = item['city_ascii'].toString().toLowerCase().indexOf(val.toString().toLowerCase()) > -1;
                    if (f1) {
                        return true;
                    } else {
                        return false;
                    }
                });
            } else {
                this.locationList = this.fulldata;
            }
        } else {
            if (val && val.trim() !== '') {
                this.countryList = this.fullCountryList.filter((item) => {
                    const f1 = item.toString().toLowerCase().indexOf(val.toString().toLowerCase()) > -1;
                    if (f1) {
                        return true;
                    } else {
                        return false;
                    }
                });
            } else {
                this.countryList = this.fullCountryList;
            }
        }
    }

    setData(data) {
        this.data = data
    }

    selectCountry(val) {
        this.selectedCountry = val;
    }

    close() {
        if (this.data != null)
            this.modalController.dismiss(this.data);
    }
}
