import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { DataService } from '../services/data.service';

@Component({
    selector: 'app-image-viewer',
    templateUrl: './image-viewer.page.html',
    styleUrls: ['./image-viewer.page.scss'],
})
export class ImageViewerPage implements OnInit {
    data: any;
    image: any;
    text: any;

    constructor(
        private navParams: NavParams,
        public modalController: ModalController,
        private dataService: DataService
    ) { }

    async ngOnInit() {
        this.data = await this.navParams.get('data');
        this.image = await this.data.recivedImage;
        this.text = await this.data.recivedMessage;
    }

    ionViewWillEnter() {
        this.dataService.checkSession();
    }

    close() {
        this.modalController.dismiss();
    }

}
