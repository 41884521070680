import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    { path: 'tabs', loadChildren: './tabs/tabs.module#TabsPageModule' },
    { path: "subscribe", loadChildren: "./subscribe/subscribe.module#SubscribePageModule" },
    {
        path: "",
        redirectTo: "/tabs/home",
        pathMatch: "full"
    },
    { path: 'user-login', loadChildren: './user-login/user-login.module#UserLoginPageModule' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            useHash: true
        })
    ],
    exports: [RouterModule]
})

export class AppRoutingModule { }