import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { DataService } from '../services/data.service';

@Component({
    selector: 'app-benefactor',
    templateUrl: './benefactor.page.html',
    styleUrls: ['./benefactor.page.scss'],
})
export class BenefactorPage implements OnInit {
    data: any;

    constructor(
        public modalController: ModalController,
        private navParams: NavParams,
        private dataService: DataService
    ) { }

    async ngOnInit() {
        this.data = this.navParams.get('data');
        this.data = this.data.split(' | ');
    }

    ionViewWillEnter() {
        this.dataService.checkSession();
    }

    dismiss() {
        this.modalController.dismiss();
    }
}
