import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
    selector: 'app-privacypolicy',
    templateUrl: './privacypolicy.page.html',
    styleUrls: ['./privacypolicy.page.scss'],
})
export class PrivacypolicyPage {

    constructor(
        public modalController: ModalController,
        public inAppBrowser: InAppBrowser
    ) { }

    close() {
        this.modalController.dismiss();
    }

    openUrl(url) {
        const browser = this.inAppBrowser.create(url, '_system');
    }
}
