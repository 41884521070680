import { Component, OnInit, ViewChild, DoCheck } from '@angular/core';
import { ModalController, NavParams, LoadingController, IonRange, NavController } from '@ionic/angular';
import { Howl } from 'howler';
import { DataService } from '../services/data.service';
import { MusicService } from '../services/music.service';
import { Track } from '../track';

@Component({
    selector: 'app-mini-player',
    templateUrl: './mini-player.page.html',
    styleUrls: ['./mini-player.page.scss'],
})
export class MiniPlayerPage implements OnInit, DoCheck {

    @ViewChild('range') range: IonRange;
    player: Howl;
    duration: any;
    timeRemaining: any;
    elapsed: any;
    buffering: any;
    activeTrack: any;
    isPlaying: boolean;
    data: any;
    isStoped: boolean;
    inPlayer: boolean;
    Playlist: Track[];
    liked_songs_data: any;
    track: any;
    __repeat: number;
    mediaUrl: string;
    doMini: boolean;

    constructor(
        private dataService: DataService,
        public modalController: ModalController,
        private navParams: NavParams,
        public loadingController: LoadingController,
        private musicService: MusicService,
        public navCtrl: NavController,
    ) { }

    ngDoCheck() {
        this.isPlaying = this.musicService.isPlaying;
        this.isStoped = this.musicService.isStoped;
        this.timeRemaining = this.musicService.timeRemaining;
        this.__repeat = this.musicService.__repeat;

        this.buffering = this.musicService.buffering
        this.activeTrack = this.musicService.activeTrack

        // duration
        if (this.musicService.duration) { this.duration = this.musicService.duration }
        if (this.musicService.elapsed) { this.elapsed = this.musicService.elapsed }

        if (this.musicService.Playlist) { this.Playlist = this.musicService.Playlist }
        if (this.dataService.liked_songs_data) { this.liked_songs_data = this.dataService.liked_songs_data }
    }

    async ngOnInit() {
        this.mediaUrl = await this.dataService.getMediaUrl();

        this.track = await this.navParams.get('track');
        var playlist = await this.navParams.get('playlist');
        this.data = await this.navParams.get('data');
        let category = await this.navParams.get('category');
        this.doMini = await this.navParams.get('doMini');
        if (this.track) {
            this.start(this.track)
        }
        if (category && category != "Now Playing") {
            if (this.data) {
                this.musicService.fullData = this.data;
            }
            if (playlist) {
                this.musicService.Playlist = playlist;
            }
            if (this.track && !playlist) {
                this.musicService.Playlist = [];
            }
        }
    }

    ionViewWillEnter() {
        this.musicService.inPlayer = true;
        this.dataService.checkSession();
        let elem_repeat = document.getElementById('repeat');
        if (elem_repeat) {
            if (this.musicService.__repeat !== 1) {
                elem_repeat.classList.remove('ion-color-light')
                elem_repeat.classList.add('ion-color-pink')
            } else {
                elem_repeat.classList.remove('ion-color-pink')
                elem_repeat.classList.add('ion-color-light')
            }
        }
        if (this.doMini) {
            this.closePage(false);
        }
    }

    start(track: any) {
        this.musicService.start(track);
    }

    closePage(stop) {
        if (stop) {
            this.musicService.stopPlayer();
        }
        this.modalController.dismiss(false);
    }

    togglePlayer(pause: boolean) {
        this.musicService.togglePlayer(pause)
    }

    seek() {
        this.musicService.player.seek(this.range.value);
    }

    ngOnDestroy() {
        this.musicService.inPlayer = false;
    }

    next() {
        this.musicService.next();
    }

    prev() {
        this.musicService.prev();
    }

    async like(track: Track) {
        var song = this.Playlist.find(e => e._id == track._id);
        var obj = {
            _id: song._id
        }
        this.musicService.addToLiked(obj);
    }

    goToNowPlaying() {
        this.navCtrl.navigateForward(['tabs/home/listen/list'], { queryParams: { Category: 'Now Playing' } });
        this.closePage(false);
    };

    async removeLike(track: any) {
        await this.musicService.removeFromLiked(track);
    }

    inLikedSongs(track: Track) {
        if (track) {
            if (this.liked_songs_data.find((e: any) => e.data.musicId == track._id)) {
                return true;
            } else { return false }
        }
    }

    /**
     * @description Toggle to repeat mode
     * @__repeat 1 | No repeat after list
     * @__repeat 2 | Repeat the list on end
     * @__repeat 3 | Repeat one song
     */
    repeat(event: any) {
        switch (this.musicService.__repeat) {
            case 1:
                this.musicService.__repeat = 2;
                if (event.target.classList.contains('ion-color-light')) {
                    event.target.classList.remove('ion-color-light');
                    event.target.classList.add('ion-color-pink');
                }
                break;
            case 2:
                this.musicService.__repeat = 3
                break
            case 3:
                this.musicService.__repeat = 1;
                if (event.target.classList.contains('ion-color-pink')) {
                    event.target.classList.remove('ion-color-pink');
                    event.target.classList.add('ion-color-light');
                }
                break;
        }
    }
}