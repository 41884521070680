import { Component, OnInit } from '@angular/core';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { Platform, NavParams, AlertController } from '@ionic/angular';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { DataService } from '../services/data.service';
import { Device } from '@ionic-native/device/ngx';

@Component({
    selector: 'app-theme-model',
    templateUrl: './theme-model.page.html',
    styleUrls: ['./theme-model.page.scss'],
})

export class ThemeModelPage implements OnInit {

    data: any;
    id: any;

    constructor(
        public alertController: AlertController,
        private plt: Platform,
        private androidPermissions: AndroidPermissions,
        private localNotifications: LocalNotifications,
        private file: File,
        private device: Device,
        private fileOpener: FileOpener,
        private transfer: FileTransfer,
        private navParams: NavParams,
        private dataService: DataService
    ) { }

    changeTheme(name) {
        var el = document.getElementById(this.id);
        var fontSize = parseFloat(el.style.fontSize);
        if (name == 'white') {
            el.style.cssText = `
                --background: white;
                --color: black;
            `;
        }
        if (name == 'black') {
            el.style.cssText = `
                --background: black;
                --color: white;
            `;
        }
        if (name == 'sepia') {
            el.style.cssText = `
                --background: #f8f0db;
                --color: #4b4943;
            `;
        }
        if (name == 'green') {
            el.style.cssText = `
                --background: #c5e7ce;
                --color: #39413b;
            `;
        }
        el.style.fontSize = fontSize + 'px';
    }

    changeFont(font) {
        var el = document.getElementById(this.id);
        var fontSize = parseFloat(el.style.fontSize);
        if (font == '+') {
            if (fontSize == 30) { return; }
            el.style.fontSize = (fontSize + 1) + 'px';
        } else {
            if (fontSize == 12) { return; }
            el.style.fontSize = (fontSize - 1) + 'px';
        }
    }

    CheckDir() {
        this.plt.ready().then(() => {
            if (this.plt.is('android')) {
                this.file.checkDir(this.file.externalRootDirectory, 'iVaishnav').then(response => {
                    console.log('Directory exists' + response);
                    this.download();
                }).catch(err => {
                    // console.error('Directory doesn\'t exist' + JSON.stringify(err));
                    this.file.createDir(this.file.externalRootDirectory, 'iVaishnav', false).then(response => {
                        console.log('Directory create' + response);
                        this.download();
                    }).catch(err => {
                        // console.error('Directory no create' + JSON.stringify(err));
                    });
                });
            }
        });
    }

    getPermission() {
        if (this.device.platform === 'browser') {
            this.browserAlert();
        } else {
            if (this.device.platform == 'Android') {
                this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE)
                    .then(status => {
                        if (status.hasPermission) {
                            this.CheckDir();
                        } else {
                            this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE)
                                .then(status => {
                                    if (status.hasPermission) {
                                        this.CheckDir();
                                    }
                                });
                        }
                    });
            } else {
                this.download();
            }
        }

    }

    async download() {

        if (this.data.filePath && this.data.filePath != '') {

            const url = await this.dataService.getMediaUrl() + this.data.filePath;
            const fileTransfer: FileTransferObject = this.transfer.create();
            var filename;
            if (this.data.CategoryType) {
                filename = this.data.CategoryType + '.pdf';
            } else {
                filename = this.data.Category + '.pdf';
            }

            if (this.device.platform == 'iOS') {
                var downloadPath = this.file.syncedDataDirectory + '/iVaishnav/' + filename;
            } else {
                var downloadPath = this.file.externalRootDirectory + '/iVaishnav/' + filename;
            }

            fileTransfer.download(url, downloadPath).then((entry) => {

                this.localNotifications.schedule({
                    id: 1,
                    title: 'Download complete',
                    text: 'File: ' + filename,
                    actions: [{ id: 'open', title: 'Open' }],
                    data: { url: entry.toURL() },
                    launch: false,
                    foreground: true
                });

                this.localNotifications.on('open').subscribe(data => {
                    this.fileOpener.open(data.data.url, 'application/pdf')
                        .then(() => console.log('File is opened'))
                        .catch((e) => {/* console.error('Error opening file', e)*/});
                });

            }, (error) => {
                // console.error(error);
                this.localNotifications.schedule({
                    id: 1,
                    text: 'download failed!',
                    foreground: true
                });
            });
        } else {
            this.fileNotAvailble();
        }
    }

    async fileNotAvailble() {
        const alert = await this.alertController.create({
            message: 'File not available for download!',
            buttons: [{
                text: 'Ok'
            }]
        });
        await alert.present();
    }

    async browserAlert() {
        const alert = await this.alertController.create({
            message: 'Download not available on browser.',
            buttons: [{
                text: 'Ok'
            }]
        });
        await alert.present();
    }

    reset() {
        var el = document.getElementById(this.id);
        el.style.fontSize = '16px';
        this.changeTheme('white');
    }

    async ngOnInit() {
        this.data = await this.navParams.get('data');
        this.id = await this.navParams.get('id');
    }

    ionViewWillEnter() {
        this.dataService.checkSession();
    }

}
