import { Component, OnInit } from '@angular/core';
import { Platform, LoadingController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Network } from '@ionic-native/network/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DataService } from './services/data.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {

    constructor(
        public platform: Platform,
        private network: Network,
        private dataService: DataService,
        public splashScreen: SplashScreen,
        private statusbar: StatusBar,
        public loadingController: LoadingController
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusbar.styleDefault();
            this.splashScreen.hide();
        });
    }

    ngOnInit(): void {
        this.platform.ready()
            .then(() => {
                this.splashScreen.hide();
            });
        this.network.onDisconnect()
            .subscribe(() => {
                this.dataService.presentLoading({
                    message: "Please check your internet",
                    translucent: true
                });
            }, (err) => {
                // console.error(err);
            });

        this.network.onConnect()
            .subscribe(() => {
                this.dataService.stopLoader();
            });
    }
}
