import { Injectable } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { File } from '@ionic-native/file/ngx';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DataService } from './data.service';
import { LoadingController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ContactsService {

    objcontacts: any[] = [];

    constructor(
        private androidPermissions: AndroidPermissions,
        private device: Device,
        private file: File,
        private http: HttpClient,
        private dataService: DataService,
        public loadingController: LoadingController
    ) { }

    /**
     * @description This is to check permission on device and pass data to next function.
     * @param contacts Array of contacts list
     * @param fileName Name of file to created
     */
    async getPermission(data: any, fileName: string) {
        if (this.device.platform == 'Android') {
            this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE)
                .then(status => {
                    if (status.hasPermission) {
                        this.saveDataToFile(data, fileName);
                    } else {
                        this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE)
                            .then(status => {
                                if (status.hasPermission) {
                                    this.saveDataToFile(data, fileName);
                                }
                            }).catch(err => {
                                // console.error(err);
                            });
                    }
                });
        } else {
            this.saveDataToFile(data, fileName);
        }
    }

    /** 
     * @description Create file with given name and add data.
     * @param data Array of contacts list
     * @param fileName Name of file to created
     */
    async saveDataToFile(data: any, fileName: string) {
        if (this.device.platform == 'Android') {
            var dirToStore = this.file.applicationStorageDirectory;
        } else {
            var dirToStore = this.file.syncedDataDirectory;
        }
        this.file.createFile(dirToStore, fileName, true)
            .then(respCreate => {
                console.log(respCreate);
                this.file.writeExistingFile(dirToStore, fileName, JSON.stringify(data))
                    .then(respWrite => {
                        console.log(respWrite);
                        return fileName;
                    }).catch(err => {
                        // console.error(err);
                    });
            }).catch(err => {
                // console.error(err);
            });
    }

    /**
     * @description This function get deta from file and return it.
     * @param fileName Filename to get contects from
     */
    async getDataFromFile(fileName: string) {
        // if (this.device.platform == 'Android') {
        //     var dirToGet = this.file.applicationStorageDirectory;
        // } else {
        //     var dirToGet = this.file.syncedDataDirectory;
        // }
        // var e: any;
        // await this.file.readAsText(dirToGet, fileName)
        //     .then(contactsFromFile => {
        //         e = JSON.parse(contactsFromFile);
        //     }).catch(err => {
        //         console.log(err);
        //         e = [];
        //     });
        // return e;
    }

    async getSyncedContacts() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        };

        const HttpParam = new HttpParams()
            .set('deviceId', this.dataService.deviceId);

        const url = await this.dataService.getUrl();
        this.http.post(url + "/getSyncedContacts", HttpParam, httpOptions)
            .subscribe((_data: any) => {
                this.objcontacts = _data;
            }, (error: any) => {
                this.dataService.stopLoader();
            });
    }

    /**
     * @description This function recreate empty file and add empty array init
     * @param fileName File to be cleared
     */
    clearFile(fileName: string) {
        if (this.device.platform == 'Android') {
            var dirToGet = this.file.applicationStorageDirectory;
        } else {
            var dirToGet = this.file.syncedDataDirectory;
        }
        this.file.createFile(dirToGet, fileName, true)
            .then(respCreate => {
                this.file.writeExistingFile(dirToGet, fileName, JSON.stringify([]))
                    .then(respWrite => {
                    });
            });
    }
}
