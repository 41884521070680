import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Device } from '@ionic-native/device/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicStorageModule } from '@ionic/storage';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { DatePickerModule } from 'ionic4-date-picker';
import { AndroidFullScreen } from '@ionic-native/android-full-screen/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { Calendar } from '@ionic-native/calendar/ngx';
import { Contacts } from '@ionic-native/contacts/ngx';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { BenefactorPageModule } from '../app/benefactor/benefactor.module';
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { VideoPlayerPageModule } from '../app/video-player/video-player.module';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { ThemeModelPageModule } from '../app/theme-model/theme-model.module';
import { Network } from '@ionic-native/network/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { AppRate } from '@ionic-native/app-rate/ngx';
import { ImageViewerPageModule } from './image-viewer/image-viewer.module';
import { SelectContactPageModule } from './select-contact/select-contact.module';
import { PrivacypolicyPageModule } from './privacypolicy/privacypolicy.module';
import { LocationModelPageModule } from "./location-model/location-model.module";
import { MiniPlayerPageModule } from './mini-player/mini-player.module';
import { CookieService } from 'ngx-cookie-service';
import { MusicControls } from '@ionic-native/music-controls/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DatePickerModule,
    DragDropModule,
    BenefactorPageModule,
    VideoPlayerPageModule,
    ThemeModelPageModule,
    ImageViewerPageModule,
    SelectContactPageModule,
    PrivacypolicyPageModule,
    LocationModelPageModule,
    MiniPlayerPageModule,
    IonicStorageModule.forRoot()
  ],
  providers: [
    CookieService,
    StatusBar,
    SplashScreen,
    HttpClientModule,
    Geolocation,
    AndroidFullScreen,
    FileTransfer,
    MusicControls,
    File,
    Calendar,
    Contacts,
    FileOpener,
    InAppBrowser,
    ScreenOrientation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Device,
    AndroidPermissions,
    LocalNotifications,
    Diagnostic,
    Network,
    FirebaseX,
    AppRate,
    AppVersion
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
